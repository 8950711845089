// i18nLocalesOverride:['pt-BR']

module.exports = (i18n) => {
  return {
    title: i18n.jsx.gettext('Revendedor{0}autorizado{0}de Mercado Pago', { tags: { 0: '<br />' } }),
    description: i18n.gettext(
      'Habla con tu revendedor para comprar lectores, obtener más información sobre otros productos o resolver tus dudas.',
    ),
  };
};
