require('regenerator-runtime/runtime');
require('../pages/Certificate/styles.scss');

const React = require('react');

const { AndesProvider } = require('@andes/context');
const { ImageProvider } = require('nordic/image');
const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const { hydrateRoot } = require('nordic/hydrate');

const CertificateView = require('../pages/Certificate/view');
const countryLanguage = require('../../services/utils/countryLanguage');

const props = window.__PRELOADED_STATE__;
const i18n = new I18n({ translations: props.translations });

props.locale = countryLanguage(props.siteId);

hydrateRoot(
  document.getElementById('root-app'),
  <ImageProvider prefix={props.imagesPrefix}>
    <I18nProvider i18n={i18n}>
      <AndesProvider locale={props.locale}>
        <CertificateView {...props} />
      </AndesProvider>
    </I18nProvider>
  </ImageProvider>,
);
