// i18nLocalesOverride:['es-AR','es-MX','pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const service = require('../../../../services/user');
const UserInfoCard = require('../../Molecules/UserInfoCard');
const CredentialsBody = require('../../Organisms/Shared/CredentialsBody');
const CredentialsHeader = require('../../Organisms/Shared/CredentialsHeader');

const CertificateTemplate = ({ nickname, siteId, isMobile }) => {
  const [data, setData] = React.useState({});

  const validateHash = (hash) => {
    const uuidValidate =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return uuidValidate.test(hash);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const uuidIsValid = validateHash(nickname);

        if (!uuidIsValid) {
          window.location.href = '/revendedores';

          return;
        }

        const resp = await service.getResellerInfo(nickname);

        if (Object.keys(resp).length === 0) {
          window.location.href = '/revendedores';
        }

        setData(resp);
      } catch (err) {
        window.location.href = '/revendedores';
      }
    };

    fetchData();
  }, [nickname]);

  return (
    <>
      {isMobile ? (
        <>
          <CredentialsHeader siteId={siteId} />
          <section className="certificate-container certificate-userInfoCardPosition">
            <UserInfoCard siteId={siteId} user={data} />
          </section>
        </>
      ) : (
        <CredentialsHeader siteId={siteId}>
          <UserInfoCard siteId={siteId} user={data} />
        </CredentialsHeader>
      )}
      {data?.products && data?.products?.length ? (
        <CredentialsBody data={data} isMobile={isMobile} nickname={nickname} siteId={siteId} />
      ) : null}
    </>
  );
};

CertificateTemplate.propTypes = {
  nickname: PropTypes.string,
  siteId: PropTypes.string,
  isMobile: PropTypes.bool,
};

module.exports = CertificateTemplate;
