const React = require('react');
const PropTypes = require('prop-types');

const Page = require('nordic/page');

const Commons = require('../../components/Commons');
const CertificateTemplate = require('../../components/Templates/CertificateTemplate');

const Certificate = (props) => {
  const { nickname = '', siteId, isMobile = false } = props;

  if (typeof window !== 'undefined' && window.MobileWebKit && window.MobileWebKit.topbar) {
    window.MobileWebKit.topbar.setup({
      bar_title: siteId !== 'MLA' ? 'Revendedores Point' : 'Revendedores Mercado Pago',
    });
  }

  return (
    <Page name="certificate" state={props}>
      <Commons {...props} name="certificate" />
      {typeof window !== 'undefined' && (
        <CertificateTemplate isMobile={isMobile} nickname={nickname} siteId={siteId} />
      )}
    </Page>
  );
};

Certificate.propTypes = {
  siteId: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  translations: PropTypes.shape({}),
  lowEnd: PropTypes.bool,
  deviceType: PropTypes.string,
  company: PropTypes.string,
  imagesPrefix: PropTypes.string,
  nickname: PropTypes.string,
};

module.exports = Certificate;
