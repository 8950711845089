// i18nLocalesOverride:['es-AR','es-MX','pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');
const { Button } = require('@andes/button');
const { Typography } = require('@andes/typography');
const { useI18n } = require('nordic/i18n');
const ChevronRight16 = require('@andes/icons/ChevronRight16');

const { getProductLink } = require('../../../../services/user');

const ProductDetails = ({ product, nickname, isMobile }) => {
  const { i18n } = useI18n();

  const [link, setLink] = React.useState(undefined);

  React.useEffect(() => {
    const fetchLink = async () => {
      const respLink = await getProductLink(product.buId, nickname);

      setLink(respLink?.link);
    };

    if (product.buId) {
      fetchLink();
    }
  }, [product, nickname]);

  return (
    <div className="productDetails">
      {isMobile ? (
        <>
          <Typography className="productDetails-off" component="strong" size="xs">
            {product.tag}
          </Typography>
          <Typography className="productDetails-description" component="h3" typpe="title">
            {i18n.gettext(product.name)}
          </Typography>
          {product.image && (
            <Image lazyload className="productDetails-image" src={`certificate/${product.image}`} />
          )}
          {product.button && (
            <Button
              fullWidth
              className="productDetails-button"
              hierarchy="transparent"
              href={link}
              target="_blank"
            >
              {i18n.gettext(product.button.text)}
              <ChevronRight16 fill="#009ee3" />
            </Button>
          )}
        </>
      ) : (
        <>
          {product.image && (
            <Image lazyload className="productDetails-image" src={`certificate/${product.image}`} />
          )}
          <div className="productDetails-texts">
            <Typography className="productDetails-off" component="strong" size="xs">
              {product.tag}
            </Typography>
            <Typography className="productDetails-description" component="h3" typpe="title">
              {i18n.gettext(product.name)}
            </Typography>
            {product.button && (
              <Button className="productDetails-button" href={link} target="_blank">
                {i18n.gettext(product.button.text)}
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

ProductDetails.defaultProps = {
  product: {},
  isMobile: false,
  nickname: '',
};

ProductDetails.propTypes = {
  product: PropTypes.shape({
    name: '',
    button: {},
    tag: '',
    image: '',
    buId: 0,
  }),
  isMobile: PropTypes.bool,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  nickname: PropTypes.string,
};

module.exports = ProductDetails;
