// i18nLocalesOverride:['es-MX']

module.exports = (i18n) => {
  return {
    title: i18n.jsx.gettext('Representante{0}autorizado Point', { tags: { 0: '<br />' } }),
    description: i18n.gettext(
      'Habla con tu representante para comprar lectores, obtener más información sobre otros productos o resolver tus dudas.',
    ),
  };
};
