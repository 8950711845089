const formatDateWithoutDay = (date, siteId = 'MLA') => {
  const language = {
    MLA: 'es-AR',
    MLB: 'pt-BR',
    MLC: 'es-CL',
    MLM: 'es-MX',
  };

  const formatedDate = date ? new Date(date) : new Date();

  return new Intl.DateTimeFormat(language[siteId], {
    month: 'long',
    year: 'numeric',
  }).format(formatedDate);
};

module.exports = {
  formatDateWithoutDay,
};
