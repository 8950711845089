// i18nLocalesOverride:['es-AR']

module.exports = (i18n) => {
  return {
    title: i18n.gettext('Disfruta de los beneficios exclusivos que tu revendedor te ofrece'),
    activeProducts: ['POINT', 'QR'],
    products: {
      QR: {
        buId: 2,
        id: 'QR_CODE_ECO',
        name: i18n.gettext('Ofrecé pagos con el QR impreso y facilitá las ventas en tu local'),
        tag: i18n.gettext('Tasa 0 durante 3 meses'),
        image: 'new-qr-mla.png',
      },
      LINK_DE_PAGO: {
        buId: 4,
        id: 'LINK_DE_PAGO_ECO',
        name: i18n.gettext('Comparte el enlace de link de pago y venta donde quiera que estés'),
        tag: i18n.gettext('25% de descuento en tasas'),
        image: 'link-de-pago-mla.png',
        button: {
          text: i18n.gettext('Conocer link de pago'),
          url: '',
        },
      },
      POINT: {
        id: 'POINT',
        name: i18n.gettext('Cobrá con Point y recibí el dinero de todas las ventas al instante'),
        tag: i18n.gettext('Atención personalizada'),
        image: 'point-mla.png',
      },
    },
  };
};
