// i18nLocalesOverride:['es-AR','es-MX','pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const Image = require('nordic/image');
const Typography = require('@andes/typography');
const injectI18n = require('nordic/i18n/injectI18n');

const { formatDateWithoutDay } = require('../../../../services/utils/formatDate');

const UserInfoCard = ({ user, siteId, i18n }) => {
  const contactData = [
    user?.city &&
      user?.state && {
        imageSrc: 'certificate/icon-map.svg',
        text: `${user?.city} - ${user?.state}`,
      },
    user?.phoneNumber && {
      imageSrc: 'certificate/icon-phone.svg',
      text: user?.phoneNumber,
    },
    user?.email && {
      imageSrc: 'certificate/icon-email.svg',
      text: user?.email,
    },
  ].filter(Boolean);

  return (
    <div className="user-info-card">
      <ul>
        <li className="user-info-card-item">
          {user.thumbnail ? (
            <Image lazyload className="user-info-card-avatar" src={user.thumbnail} />
          ) : (
            <span className="user-info-card-avatar user-info-card-placeholder-avatar" />
          )}
          <Typography className="user-info-card-details" component="p">
            <Typography className="user-info-card-details-userName" component="strong">
              {user.fullName}
            </Typography>
            <Typography
              className="user-info-card-details-since"
              color="secondary"
              component="small"
              size="xs"
            >
              {i18n.gettext(
                'Revendedor desde {0}',
                formatDateWithoutDay(user.resellerRegistrationDate, siteId),
              )}
            </Typography>
          </Typography>
        </li>
        {contactData?.map((item) => (
          <li key={item?.text} className="user-info-card-item">
            <Image lazyload className="user-info-card-icon" src={item?.imageSrc} />
            {item?.text}
          </li>
        ))}
      </ul>
    </div>
  );
};

UserInfoCard.defaultProps = {
  user: {
    thumbnail: '',
    email: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    resellerRegistrationDate: '',
    city: '',
    state: '',
  },
  siteId: '',
};

UserInfoCard.propTypes = {
  user: PropTypes.shape({
    thumbnail: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    resellerRegistrationDate: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    fullName: PropTypes.string,
  }),
  siteId: PropTypes.string,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

module.exports = injectI18n(UserInfoCard);
