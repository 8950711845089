// i18nLocalesOverride:['es-AR','es-MX','pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const Typography = require('@andes/typography');
const injectI18n = require('nordic/i18n/injectI18n');

const configs = require('./configs');

const CredentialsHeader = ({ children, siteId, i18n }) => {
  const { title, description } = configs[siteId || 'MLA'](i18n);

  return (
    <header className="credentials-header">
      <div className="certificate-container">
        <div className="credentials-header-texts">
          <Typography className="credentials-header-title" component="h1" size="l" type="title">
            {title}
          </Typography>
          <Typography className="credentials-header-description" component="p" size="s">
            {description}
          </Typography>
        </div>
        <div className="credentials-header-cardUser">{children}</div>
      </div>
    </header>
  );
};

CredentialsHeader.defaultProps = {
  siteId: 'MLA',
  children: '',
};

CredentialsHeader.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({
      gettext: PropTypes.func,
    }),
  }).isRequired,
  children: PropTypes.node,
  siteId: PropTypes.string,
};

module.exports = injectI18n(CredentialsHeader);
